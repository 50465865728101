import React, { useContext } from 'react';
import { DataContext } from '@visx/xychart';
import { useTheme } from '@mui/material';
import moment from 'moment';

const CustomLine = ({ datum, xAccessor }) => {
  const theme = useTheme();
  // Retrieve the xScale (and others if needed) from the DataContext.
  const { xScale, yScale, innerHeight } = useContext(DataContext);

  // Ensure xScale is available (it will be, if this component is a child of XYChart).
  if (!xScale || !yScale) return null;

  // Compute the x coordinate by passing your datum’s value through the xAccessor
  // and then through xScale.
  const x =
    xScale(xAccessor(datum)) + (xScale.bandwidth ? xScale.bandwidth() / 2 : 0);
  const yBottom = yScale(0);

  return (
    <svg style={{ position: 'absolute', pointerEvents: 'none' }}>
      <line
        x1={x}
        x2={x}
        y1={yBottom - innerHeight - 20}
        y2={yBottom}
        stroke={theme.palette.grey[600]}
        strokeWidth={1}
      />
      <text
        x={x-1} // Offset to line up with dot
        y={yBottom - innerHeight - 25}
        fill={theme.palette.grey[600]}
        fontSize='0.8rem'
        fontFamily='Arial'
        textAnchor='middle'>
        {`Today • ${moment().format('MMM D')}`}
      </text>
    </svg>
  );
};

export default CustomLine;
