// MUI
import { Dialog, DialogContent } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { dark_theme } from '../lib/theme';
// React
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// Components
import {
  DoseList,
  CycleGraph,
  DrugCardForm,
  CycleInfo,
  AncillariesList,
  AncillaryForm,
  PeptidesList,
  PeptideForm,
  BloodList,
  BloodForm,
  PopoverAuth,
} from 'components';
// Helpers
import { customToast } from 'helpers/customToast';
// Custom Hooks
import { useGetDosesByCycleId } from 'features/doses/hooks';
import { useGetBloodsByCycleId } from 'features/bloods/hooks';
import { useGetAncillariesByCycleId } from 'features/ancillaries/hooks';
import { useGetPeptidesByCycleId } from 'features/peptides/hooks';
// Redux
import {
  closeAuth,
  openLogin,
  openSignUp,
  selectAuthInfo,
} from 'features/auth/authSlice';
import { selectCycleInfo } from 'features/cycles/cyclesSlice';
import {
  selectDashboardInfo,
  setPrivacyPolicyVisible,
} from 'features/dashboard/dashboardSlice';
import JournalList from './Journal/JournalList';
// Components
import { PrivacyPolicy } from './';

function Dashboard() {
  // React
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // Redux
  const privacy_policy_visible = useSelector(
    (state) => state.dashboard.privacy_policy_visible
  );
  const { show_login, show_signup } = useSelector(selectAuthInfo);
  const { creating } = useSelector(selectCycleInfo);
  const { menu_selected } = useSelector(selectDashboardInfo);
  const dispatch = useDispatch();
  // React Query
  const { data: doses } = useGetDosesByCycleId();
  const { data: bloods } = useGetBloodsByCycleId();
  const { data: ancillaries } = useGetAncillariesByCycleId();
  const { data: peptides } = useGetPeptidesByCycleId();
  // MUI
  const smallScreen = useMediaQuery(dark_theme.breakpoints.down('sm'));
  const extraLargeScreen = useMediaQuery(dark_theme.breakpoints.up('xl'));
  // Component State
  const [dialog_open, setDialogOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [current_item_id, setCurrentItemId] = useState('');

  useEffect(() => {
    if (pathname === '/') {
      // Do nothing if you're already on the home route
    } else if (pathname === '/login') {
      dispatch(openLogin());
    } else if (pathname === '/signup') {
      dispatch(openSignUp('url'));
    } else if (pathname === '/privacy-policy') {
      dispatch(setPrivacyPolicyVisible(true));
    } else {
      navigate('/');
    }
  }, [pathname, navigate, dispatch]);

  const handleEdit = (id) => {
    !id && customToast('error', 'No item selected');
    setCurrentItemId(id);
    setEditing(true);
    setDialogOpen(true);
  };

  const clearEditing = () => {
    setEditing(false);
    setCurrentItemId('');
  };

  const handleCreate = () => {
    if (creating) {
      customToast('error', 'Please create cycle first');
    } else {
      clearEditing();
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(clearEditing, 300);
  };

  const handleAuthClose = () => {
    dispatch(closeAuth());
    if (pathname !== '/') {
      navigate('/');
    }
  };

  const handlePrivacyPolicyClose = () => {
    dispatch(setPrivacyPolicyVisible(false));
    if (pathname !== '/') {
      navigate('/');
    }
  };

  const getDialogDimensions = () => {
    if (extraLargeScreen) {
      // Define dimensions for extra large screens
      return { height: '50%', width: '40%', maxWidth: 800 };
    }
    if (smallScreen) {
      // Define dimensions for extra small screens
      return { height: '100%', width: '100%' };
    }
    // Define dimensions for other screens
    return { height: show_signup ? 550 : 500, width: show_signup ? 500 : 450 };
  };

  const dialogDimensions = getDialogDimensions();

  return (
    <div className='dashboard'>
      <CycleInfo />
      <div className='drug-form'>
        {
          {
            COMPOUNDS: (
              <DrugCardForm
                handleDialogClose={handleDialogClose}
                clearEditing={clearEditing}
                current_drug_id={current_item_id}
                open={dialog_open}
                editing={editing}
              />
            ),
            ANCILLARIES: (
              <AncillaryForm
                handleDialogClose={handleDialogClose}
                clearEditing={clearEditing}
                current_ancillary_id={current_item_id}
                open={dialog_open}
                editing={editing}
              />
            ),
            'PEPTIDES+': (
              <PeptideForm
                handleDialogClose={handleDialogClose}
                clearEditing={clearEditing}
                current_peptide_id={current_item_id}
                open={dialog_open}
                editing={editing}
              />
            ),
            'BLOOD WORK': (
              <BloodForm
                handleDialogClose={handleDialogClose}
                clearEditing={clearEditing}
                current_blood_id={current_item_id}
                open={dialog_open}
                editing={editing}
              />
            ),
          }[menu_selected]
        }
      </div>

      {!creating &&
        {
          COMPOUNDS: (
            <div className='drug-list'>
              <DoseList
                doses={doses}
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </div>
          ),
          ANCILLARIES: (
            <div className='ancillaries-list'>
              <AncillariesList
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </div>
          ),
          'PEPTIDES+': (
            <div className='peptides-list'>
              <PeptidesList
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </div>
          ),
          'BLOOD WORK': (
            <div className='blood-work'>
              <BloodList handleCreate={handleCreate} handleEdit={handleEdit} />
            </div>
          ),
          JOURNAL: (
            <div className='journal'>
              <JournalList
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </div>
          ),
        }[menu_selected]}

      {(doses?.length > 0 ||
        peptides?.length > 0 ||
        bloods?.length > 0 ||
        ancillaries?.length > 0) &&
        !creating && <CycleGraph />}

      <ThemeProvider theme={dark_theme}>
        <Dialog
          open={show_login}
          onClose={handleAuthClose}
          fullScreen={smallScreen}
          maxWidth={false}
          sx={{
            '& .MuiDialog-paper': {
              ...dialogDimensions,
              margin: smallScreen ? 0 : 'auto',
            },
          }}>
          <DialogContent
            sx={{
              height: '100%',
              width: '100%',
              backgroundColor: dark_theme.palette.background.default,
            }}>
            <PopoverAuth />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
      <Dialog open={privacy_policy_visible} onClose={handlePrivacyPolicyClose}>
        <DialogContent sx={{ pt: 0 }}>
          <PrivacyPolicy />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Dashboard;
