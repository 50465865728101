// ------------------ INITIAL SETUP ------------------

// Importing the necessary data and libraries for drug information and date manipulation.
import { DRUGS, DRUG_FACTS, PEPTIDES } from '../constants';
import moment from 'moment';

// visx data format
// const cityTemperature: CityTemperature[] = [
//   {
//     date: '20111001',
//     'New York': '63.4',
//     'San Francisco': '62.7',
//     Austin: '72.2',
//   },
//   ...
// ];

// Set up some constants for calculation precision and initialize a few global variables.
let half_lives = [];
let precision = 4;
let display_precision = 2;
let max_release = 0;

// ------------------ CORE CALCULATIONS ------------------

// Function to compute the remaining effective dose after a given number of days based on the drug's decay rate.
const half_life = (days, effective_dose, decay) => {
  return effective_dose * Math.E ** (-days * decay);
};

// Function to compute the drug's release rate for a given day.
const releaseRate = (days, dose, hl_days, percent_active) => {
  let decay = Math.LN2 / hl_days;
  return decay * half_life(days, dose * percent_active, decay);
};

// Create an array representing the release rate of the drug for each day of the cycle.
const generateHalfLifeArray = (
  full_name,
  dose,
  step = 1,
  cycle_length = 140
) => {
  let arr = [];
  const hl_days =
    DRUGS[full_name]?.half_life_days || PEPTIDES[full_name]?.half_life_days;
  const percent_active =
    DRUGS[full_name]?.dose_percentage_active ||
    PEPTIDES[full_name].dose_percentage_active;

  for (let days = 0; days < cycle_length; days += step) {
    let rl_rate = +releaseRate(days, dose, hl_days, percent_active).toFixed(
      precision
    );
    if (rl_rate > 0) {
      arr.push(rl_rate);
    } else {
      break;
    }
  }
  return arr;
};

// Recursive function to compute the cumulative dose of the drug up to a certain day.
// It considers multiple administrations (shots) of the drug and their decay.
const computeSumAtIDays = (gaps, shots, days) => {
  if (shots === 0 || days < 0) {
    return 0;
  } else if (days >= half_lives.length) {
    return computeSumAtIDays(gaps, shots - 1, days - gaps[shots % gaps.length]);
  } else {
    return (
      half_lives[days] +
      computeSumAtIDays(gaps, shots - 1, days - gaps[shots % gaps.length])
    );
  }
};

// Function to compute the offset from the cycle start to either a specific date or based on a starting week.
export const computeOffset = (
  cycle_start_date,
  start_wk,
  frequency,
  date,
  step
) => {
  let days_diff = 0;

  // Ensure cycle_start_date is a Moment.js object
  cycle_start_date = moment(cycle_start_date);

  if (frequency === 0) {
    date = moment(date).startOf('day');
    days_diff = date.diff(cycle_start_date, 'days');
    return days_diff / step;
  } else if (frequency === 69) {
    // MWF case: Calculate difference to next Monday
    const dayOfWeek = cycle_start_date.day(); // 0-Sunday, 1-Monday, ..., 6-Saturday
    const daysToNextMonday = (7 - dayOfWeek + 1) % 7; // Calculate days to the next Monday

    // Adjust the start_wk based on daysToNextMonday
    return ((start_wk - 1) * 7 + daysToNextMonday) / step;
  } else {
    // For all other frequencies, just use the cycle_start_date as anchor
    return ((start_wk - 1) * 7) / step;
  }
};

// ------------------ GRAPH DATA GENERATION ------------------

// Produce the data needed to plot the graph. It iterates over each time step and calculates the dose data for each drug.
const generateGraphData = (
  cycle_start_date,
  cycles_by_drug,
  total_steps,
  step
) => {
  let graph_data = [];
  for (let i = 0; i < total_steps; i += 1) {
    let data_point = {};
    for (const compound of Object.keys(cycles_by_drug)) {
      if (cycles_by_drug[compound].length > 1) {
        // build each cycle, and total
        let compound_total = 0;
        for (const { cycle, name, ester, id } of cycles_by_drug[compound]) {
          const full_name = ester ? `${name}-${ester}` : name;
          data_point[full_name + '_' + id] = cycle[i].toString();
          compound_total += cycle[i];
        }
        data_point[compound] = compound_total.toFixed(display_precision);
      } else {
        // if only one cycle for compound, build that only
        const { cycle, ...cycle_metadata } = cycles_by_drug[compound][0];
        data_point[compound] = cycle[i].toString();
      }
    }
    for (let key in data_point) {
      max_release = Math.max(max_release, data_point[key]);
    }
    graph_data.push({
      date: moment(cycle_start_date)
        .startOf('day')
        .add(i * step, 'days')
        .format('MM/DD/YYYY'),
      days: (i * step).toFixed(1),
      ...data_point,
    });
  }
  // console.log('graph_data', graph_data);
  return [graph_data, max_release];
};

// Main function to generate graph data for the drug cycles.
// For each drug, it computes the offset, determines number of administrations (shots), calculates the cumulative dose, and stores the results.
export const roidCalc = (
  drugs = [],
  cycle_start_date = moment(),
  cycle_length = 140,
  step = 0.5
) => {
  if (drugs.length === 0) return [];
  max_release = 0;
  let cycles_by_drug = {};
  let total_steps = cycle_length / step;

  drugs.forEach((drug) => {
    const { id, name, ester, dose, frequency, start_wk, stop_wk, date } = drug;
    // figure out the offset for the entire injection window
    let offset = computeOffset(
      cycle_start_date,
      start_wk,
      frequency,
      date,
      step
    );

    // skip if the offset is beyond total steps
    if (offset >= total_steps) return;

    // Full name as normal:
    const full_name = ester ? `${name}-${ester}` : name;

    // ----------------------------------
    // Check if this compound/ester is a blend
    // ----------------------------------
    let is_blend = false;
    let blend_esters = [];
    const compound_obj = DRUG_FACTS.Compounds[name];

    if (compound_obj) {
      // e.g. compound_obj might be DRUG_FACTS.Compounds.Testosterone
      const blend_data = compound_obj[full_name];
      if (blend_data && blend_data.is_blend && Array.isArray(blend_data.esters)) {
        is_blend = true;
        blend_esters = blend_data.esters;
      }
    }

    // Next, calculate how many shots we have in total
    let frequencyShots;
    if (frequency === 69) {
      // MWF => 3 shots per week
      frequencyShots = 3 * (stop_wk - start_wk + 1);
    } else {
      frequencyShots =
        frequency === 0
          ? 1
          : Math.round(((stop_wk - start_wk + 1) * 7) / frequency);
    }

    // Helper to build the cycle array from the half-life data
    const buildCycle = (localShots, localGaps) => {
      let cycle_arr = [];
      // fill zeros up to offset
      for (let d = 0; d < offset; d++) {
        cycle_arr.push(0);
      }
      // now fill the sum for the remaining days
      for (let d = 0; d < total_steps - offset; d++) {
        let sumVal = +computeSumAtIDays(localGaps, localShots, d).toFixed(
          display_precision
        );
        cycle_arr.push(sumVal);
      }
      return cycle_arr;
    };

    // Helper to compute array of gaps based on the frequency
    const makeGaps = () => {
      if (frequency === 69) {
        // MWF => 2,3,2 day pattern
        return [2, 3, 2].map((gap) => Math.round(gap / step));
      } else {
        return Array(frequencyShots)
          .fill(frequency)
          .map((gap) => Math.round(gap / step));
      }
    };

    // ----------------------------------------------------------------
    // If it's a blend, iterate over each sub-ester and generate data
    // ----------------------------------------------------------------
    if (is_blend) {
      blend_esters.forEach((sub) => {
        // e.g. sub = { name: 'Testosterone-Propionate', ratio: 0.3 }
        // partial dose:
        const partialDose = dose * sub.ratio;
        half_lives = generateHalfLifeArray(
          sub.name,
          partialDose,
          step,
          cycle_length
        );

        let localGaps = makeGaps();
        let subCycle = buildCycle(frequencyShots, localGaps);

        // unify them under the main compound name (e.g. 'Testosterone') so they sum up
        // but keep a distinct ester label so we can see it in the final chart
        // e.g. 'Sustanon 250-Testosterone-Propionate'
        // or simpler: 'Sustanon 250-Propionate'
        // We'll do an approach that extracts whatever's after the dash in sub.name
        const subEsterCore = sub.name.replace(`${name}-`, '');
        const subEsterLabel = `${ester}-${subEsterCore}`;

        if (!cycles_by_drug[name]) {
          cycles_by_drug[name] = [];
        }
        cycles_by_drug[name].push({
          ...drug,
          name, // => e.g. 'Testosterone'
          ester: subEsterLabel,
          dose: partialDose,
          cycle: subCycle,
        });
      });
    } else {
      // ------------------------------------------------------------
      // Normal single-ester logic
      // ------------------------------------------------------------
      half_lives = generateHalfLifeArray(full_name, dose, step, cycle_length);
      let localGaps = makeGaps();
      let normalCycle = buildCycle(frequencyShots, localGaps);

      if (name in cycles_by_drug) {
        cycles_by_drug[name].push({ ...drug, cycle: normalCycle });
      } else {
        cycles_by_drug[name] = [{ ...drug, cycle: normalCycle }];
      }
    }
  });

  return generateGraphData(cycle_start_date, cycles_by_drug, total_steps, step);
};
