// MUI
import {
  IconButton,
  Card,
  Typography,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Delete,
  Edit,
  MoreVert,
  VisibilityOff,
  Visibility,
} from '@mui/icons-material';
// React
import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewItem } from 'components';
// Features
import {
  toggleBlood,
  toggleMarker,
  selectBloodsInfo,
} from 'features/bloods/bloodsSlice';
import { useDeleteBloodResultMutation } from 'features/bloods/bloodsAPI';
import { openSignUp } from 'features/auth/authSlice';
// Custom Hooks
import { useGetBloodsByCycleId } from 'features/bloods/hooks';
// Helpers
import { BLOOD_UNITS } from 'constants/index';
import moment from 'moment';
import { customToast } from 'helpers/customToast';
import { lighterColorHash, variableLightColorHash } from 'helpers/colorHash';

function BloodList({ handleCreate, handleEdit }) {
  /// Redux
  const dispatch = useDispatch();
  const { hidden_results, hidden_markers } = useSelector(selectBloodsInfo);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  // React Query
  const { data: bloods, isLoading, isFetching } = useGetBloodsByCycleId();
  const [deleteBloodResult] = useDeleteBloodResultMutation();

  const theme = useTheme();

  // Component State
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [activeBloodId, setActiveBloodId] = useState(null);

  const blood_cols = useMemo(() => {
    if (bloods?.length) {
      const cols_present = new Set();
      let cols_arr = [];
      const blood_markers = Object.keys(BLOOD_UNITS);
      bloods.forEach((row) => {
        blood_markers.forEach((marker) => {
          if (row[marker]) cols_present.add(marker);
        });
      });
      blood_markers.forEach((marker) => {
        if (cols_present.has(marker)) cols_arr.push(marker);
      });
      return cols_arr;
    } else {
      return [];
    }
  }, [bloods]);

  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
    setActiveBloodId(e.currentTarget.id);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setActiveBloodId(null);
  };

  const handleDelete = (blood_result_id) => {
    !blood_result_id && customToast('error', 'No blood result selected');
    setAnchorElNav(null);
    is_authenticated
      ? deleteBloodResult(blood_result_id)
      : dispatch(openSignUp('delete_bloods')) &&
        customToast(
          'info',
          'Please Sign Up or Login to add and remove Blood Work',
          5000
        );
  };

  if (isLoading || isFetching) {
    return <></>;
  }

  const table_header_style = {
    // borderBottom: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
  };

  return (
    <Box sx={{ mx: 2, display: 'flex', flexDirection: 'column' }}>
      {bloods?.length !== 0 && (
        <Card sx={{ display: 'flex', maxHeight: 400 }}>
          <TableContainer>
            <Table stickyHeader aria-label='simple table' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align='left' sx={{ ...table_header_style }}>
                    Actions
                  </TableCell>
                  <TableCell sx={{ ...table_header_style }}>Date</TableCell>
                  {blood_cols.map((col, i) => (
                    <TableCell
                      key={col + '_' + i + 'col_cell'}
                      align='center'
                      sx={{ ...table_header_style }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {col}
                        <IconButton
                          id={i + '_eye_' + col}
                          onClick={() => dispatch(toggleMarker(col))}
                          size='small'
                          sx={{
                            backgroundColor:
                              col in hidden_markers
                                ? ''
                                : lighterColorHash(col),
                            ml: 1,
                            '&:hover': {
                              backgroundColor:
                                col in hidden_markers
                                  ? ''
                                  : variableLightColorHash(col, 30),
                            },
                          }}>
                          {col in hidden_markers ? (
                            <VisibilityOff fontSize='small' />
                          ) : (
                            <Visibility fontSize='small' />
                          )}
                        </IconButton>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {bloods.map((row) => (
                  <TableRow
                    key={row.id + '_' + row.date}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align='left'>
                      <Box>
                        <IconButton
                          id={row.id}
                          onClick={() => handleEdit(row.id)}>
                          <Edit fontSize='small' />
                        </IconButton>
                        <IconButton
                          id={row.id}
                          onClick={() => dispatch(toggleBlood(row.id))}>
                          {row.id in hidden_results ? (
                            <VisibilityOff fontSize='small' />
                          ) : (
                            <Visibility fontSize='small' />
                          )}
                        </IconButton>
                        <IconButton id={row.id} onClick={handleOpenNavMenu}>
                          <MoreVert fontSize='small' />
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell component='th' scope='row' sx={{}}>
                      {moment(row.date).format('MMM Do YYYY')}
                    </TableCell>
                    {blood_cols.map((key, i) => (
                      <TableCell
                        key={key + '_' + i + 'row_cell'}
                        align='center'
                        sx={{}}>
                        {row[key] ? (
                          <Box>
                            <Typography variant='body2'>
                              {row[key] + ' '}
                            </Typography>
                            <i>{BLOOD_UNITS[key]}</i>
                          </Box>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id='menu-appbar'
            anchorEl={anchorElNav}
            keepMounted
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}>
            <MenuItem
              onClick={() => {
                handleDelete(activeBloodId);
              }}>
              <ListItemIcon>
                <Delete fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </Card>
      )}
      <Box sx={{ pt: bloods?.length ? 1 : 0 }}>
        <NewItem
          handleCreate={handleCreate}
          has_items={bloods?.length}
          title={'Add New Blood Test Results'}
        />
      </Box>
    </Box>
  );
}

export default BloodList;
