import React from 'react';
import { lighterColorHash, variableLightColorHash } from 'helpers/colorHash';
import { useTheme, Chip, Box, Card, Stack } from '@mui/material';
import { DRUGS } from 'constants';
import moment from 'moment';

const DosesTooltip = ({ tooltipData, colorScale }) => {
  const theme = useTheme();
  const { date, days, ...doses } = tooltipData.nearestDatum.datum;
  const sorted_doses = Object.entries(doses).reverse();
  const doses_by_drug = {};
  const cards = [];

  sorted_doses.forEach(([key, dose]) => {
    const name = key.split('_')[0].split('-')[0];
    if (+parseFloat(dose).toFixed(2) > 0) {
      if (doses_by_drug[name]) {
        doses_by_drug[name].push([key, doses[key]]);
      } else {
        doses_by_drug[name] = [[key, doses[key]]];
      }
    }
  });

  for (const name in doses_by_drug) {
    let items = [];
    doses_by_drug[name].forEach(([key, value], index) => {
      if (!key.includes('_')) {
        items.push(
          <Box
            key={key + 'TOTAL_box' + value}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              background: lighterColorHash(name),
            }}>
            <Chip
              size='small'
              key={key + 'TOTAL_chip_name' + value}
              label={`${key}`}
              sx={{
                borderRadius: '0px',
                backgroundColor: 'transparent',
              }}
            />
            <Chip
              size='small'
              key={key + 'TOTAL_chip_dose' + value}
              label={`${value}`}
              sx={{
                borderRadius: '0px',
                backgroundColor: 'transparent',
              }}
            />
          </Box>
        );
      } else if (doses_by_drug[name].length > 2) {
        // Push inidividual doses if there's multiple
        // If the key is a blend, assign the blend name using elements of base_name
        const base_name = key.split('_')[0];
        const is_blend = !DRUGS[base_name];
        const blend_elements = is_blend ? base_name.split('-') : null;
        const blend_name = is_blend
          ? `[${DRUGS[blend_elements.slice(0, -1).join('-')].nickname}] ${
              DRUGS[blend_elements[0] + '-' + blend_elements[2]].nickname
            }`
          : '';
        // Push the tooltip item accordingly
        items.push(
          <Box
            key={key + 'ITEMS_box' + value}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Chip
              size='small'
              key={key + 'ITEMS_chip_name' + value}
              label={
                <>
                  {`#${index}`}
                  <Box component={'span'} sx={{ fontStyle: 'italic' }}>{` ${
                    is_blend ? blend_name : DRUGS[base_name].nickname
                  }`}</Box>
                </>
              }
              sx={{
                borderRadius: '0px',
                backgroundColor: 'transparent',
              }}
            />
            <Chip
              size='small'
              key={key + 'ITEMS_chip_dose' + value}
              label={`${value}`}
              sx={{
                borderRadius: '0px',
                backgroundColor: 'transparent',
              }}
            />
          </Box>
        );
      }
    });

    items.length > 0 &&
      cards.push(
        <Card
          key={name}
          sx={{ backgroundColor: variableLightColorHash(name, 30) }}>
          {items}
        </Card>
      );
  }

  return (
    <div>
      <Stack spacing={0.5}>
        <Card
          sx={{
            m: 0,
            p: 0,
            backgroundColor: theme.palette.primary.main,
          }}
          key={'tooltip-card'}>
          <Chip
            size='small'
            label={`${moment(Date.parse(date)).format('M/D')} • Wk ${
              Math.floor(days / 7) + 1
            } • Day ${days}`}
            sx={{
              borderRadius: '0px',
              width: '100%',
              color: theme.palette.primary.contrastText,
            }}
          />
        </Card>
        {cards}
      </Stack>
    </div>
  );
};

export default DosesTooltip;
